<template>
  <div id="app">
    <Nav />
    <div class="pageMain">
      <div class="p20">
        <div class="f16">{{titleText}} {{$t('producDeta.commission')}}</div>
        <div class="boxText pt10">
          {{$t('producDeta.title')}}
        </div>

        <div class="worksViewList pt30" v-for="(item,key) in contents" :key="key">
          <div class="worksViewMain">
            <div class="worksViewRow">
              <div class="worksViewMedia">
                <img :src="item.image" width="140" alt="">
                <!-- <div class="cd active">
                  <div class="cdPic">
                    <div class="cdPlayBtn">
                      <img src="@/assets/stop1.png" width="40" />
                    </div>
                    <img src="@/assets/w_p1.png" width="100" />
                  </div>
                  <div class="cdBlock">
                    <img src="@/assets/cd2.png" width="90" />
                  </div>
                </div> -->
              </div>
              <div class="worksViewInfo">
                <div class="f14 fb">{{$i18n.locale == 'zh'?item.name:item.name2}}</div>
                <div class="f14" style="width:59%;">{{$i18n.locale == 'zh'?item.title1:item.title2}}</div>
                <div class="worksViewPy">{{item.name3}}<br />{{item.name4}}</div>
              </div>
            </div>
            <div class="boxText pt10">
              {{$i18n.locale == 'zh'?item.content1:item.content2}}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<style scoped>
</style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      id: "1",
      dataSoure: {
        1: [
          {
            name:'赵季平',
            name2:'Zhao Jiping',
            name3:'zhao',
            name4:'jiping',
            title1: "《风雅颂之交响》",
            title2: "“Symphony of Feng-Ya-Song”",
            content1:
              "2018年赵季平先生为苏州民族管弦乐团创作作品《风雅颂之交响》。该作品以中华传统文化之诗歌滥觞《诗经》为创作宗旨，选取其中有代表性的章节，作曲家用独特的音乐语言向中华传统经典致敬，讴歌祖国、讴歌时代、讴歌人民、讴歌英雄，充分展现了领航新时代泱泱大国的形象和魅力。作品风格雄壮、典雅，共有《序·颂》、《关雎》、《玄鸟》、《幽兰操》、《国风》五个乐章。",
            content2:
              "In 2018, Mr. Zhao composed the “Symphony of Feng-Ya-Song” for the Suzhou Chinese Orchestra. Inspired by the “Classic of Poetry,” the composer had selected the prominent chapters of the collection and with his unique musical language, the work pays tribute to the classic of Zhonghua traditions, eulogizes the motherland, the era, the people, and the heroes, emitting the charm of a large country that leads the new era.",
             image: require("../../assets/media/media-1.png")
          },
          {
            name:'刘长远',
            name2:'Liu Changyuan',
            name3:'Liu',
            name4:'Changyuan',
            title1: "《丝竹的交响》",
            title2: "“Symphony of Sizhu”",
            content1:
              "刘长远是中央音乐学院作曲教授，硕士生导师，民族管弦乐学会创作委员会副主任。2018年苏州民族管弦乐团委约刘长远先生创作民族管弦乐作品《丝竹的交响》。该作品音乐材料取自江苏民歌《姑苏风光》，分两个乐章，仿佛让我们听到了太湖的船歌、寒山寺的钟声、庭院中的评弹和水乡的浪花，抒发了作者对江南水乡的赞美，以及苏州人民深爱故乡之情感，体现了中华儿女强烈的民族自豪感",
            content2:
              "Mr. Liu Changyuan is a professor at the Department of Composition at Central Conservatory of Music, master’s supervisor, and vice president of the Chinese Orchestra Composition Committee. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Liu to compose the Chinese Symphony “Symphony of Sizhu”. The material of the work was extracted from the Jiangsu folk song “Beautiful Scenery of Gusu.” Divided into two movements, the music let us hear the Taihu Lake’s boat song, bells of Hanshan Temple, Pingtan in the courtyard, and waves of the water village. The work expresses the composer’s praise towards the water village of Jiangnan and the Suzhou people’s love for the hometown, presenting a strong sense of national pride of the Zhonghua people.",
             image: require("../../assets/media/media-2.png")  
          },
          {
             name:'李滨扬',
            name2:'Li Binyang',
            name3:'Li',
            name4:'Binyang',
            title1: "《烟雨枫桥》",
            title2: "“Misty Rain on Maple Bridge”",
            content1:
              "李滨扬是中央音乐学院作曲系教授，博士生导师。2018年苏州民族管弦乐团委约李滨扬先生创作民族管弦乐作品《烟雨枫桥》。该作品曲名取自杜牧（唐）“暮烟疏雨过枫桥”。在隐约的苏州评弹与昆曲元素的穿引下，通过时而飘渺、时而清雅、时而梦幻、时而浪漫激越的音乐历程，描绘出作者心中那座令人神往、朦胧色彩中的千年枫桥，体现出作者爱家乡、爱祖国的美好情怀。",
            content2:
              "Mr. Li Binyang is a professor at the Department of Composition of Central Conservatory of Music, doctorate’s supervisor. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Li to compose the Chinese Symphony “Misty Rain on Maple Bridge.” The title of the piece was inspired by the poem line “Twilight fog and light rain over the maple bridge.” Vaguely lead by the elements of Suzhou Pingtan and Kunqu, through the music journey in the mixture of faintness, elegance, dreamy and romance with aggression, the work depicts the composer’s imagination of the fascinating millennial maple bridge in hazy colors, showing his love for the hometown and the motherland.",
            image: require("../../assets/media/media-3.png") 
          },
          {
             name:'张朝',
            name2:'Zhang Zhao',
            name3:'Zhang',
            name4:'Zhao',
            title1: "《干将莫邪幻想曲》",
            title2: "“Fantasia of Gan Jiang Mo Ye”",
            content1:
              "张朝是中国音乐家协会会员、中央民族大学音乐学院作曲教授、硕士研究生导师。2018年苏州民族管弦乐团委约张朝先生创作民族管弦乐作品《干将·莫邪幻想曲》。该作品取自苏州民间传说“干将莫邪”的感人故事，乐曲分为誓言、山雪、烈火、涅槃四个乐章。作品避开文学的叙事，突出情怀与心灵的表达，通过四个乐章的演奏，向观众传达了大爱、大义、大勇的精神力量，歌颂了人民、歌颂了英雄。",
            content2:
              "Mr. Zhang Zhao is a member of the Chinese Musicians’ Association, a professor of the Music School of Minzu University of China, and master’s supervisor. In 2018, the Suzhou Chinese Orchestra commissioned Mr. Zhang to compose the Chinese Symphony “Fantasia of Gan Jiang Mo Ye.” The work was inspired by the touching story of the Suzhou folk legend “Gan Jiang Mo Ye.” Divided into four parts, namely: The Oath, Mountain’s Snow, Inferno, and Nirvana, the work avoids literary narration and emphasizes the expression of the feelings and spirit. The music delivers the spiritual energy of great love, great righteousness and great courage to the audience, and eulogizes the people and the heroes.",
            image: require("../../assets/media/media-4.png") 
          },
        ],
        2: [
          {
             name:'郝维亚',
            name2:'Hao Weiya',
            name3:'Hao',
            name4:'Weiya',
            title1: "《来自苏州的声音》",
            title2: "“The Sounds from Suzhou”",
            content1:
              "该作品源于作曲家对苏州这个城市的热爱。今天，我们生活的环境里，已经很少有像苏州这样令人感慨不已的城市，既保持古色古韵的味道，又兼顾时代发展的需求。作品拟分三个乐章，分别从苏州的“园林”“山水”“昆曲”中汲取素材，并考虑以当代人的视角重新感受和审视苏州的独特气息，展现了苏州经过四十年改革开放的洗礼所取得的发展成就，歌颂了党的英明领导和苏州人民的勤劳与智慧！",
            content2:
              "The work was inspired by the composer’s love for the city of Suzhou. Today, it’s very fortunate for us to live in an awesome city like Suzhou which preserves the ancient taste but also meets the need of the development in the era. The work is divided into three movements, with the materials extracted from the “Garden”, “Land” and “Kunqu” of Suzhou respectively, considering the angle of view of the modern people and observing the unique features of Suzhou, it showcases the developmental achievements of Suzhou after 40 years of Reform and Opening-up, eulogizes the wise leadership of the Party and the hard work and wisdom of the people!",
            image: require("../../assets/media/media-5.png")
          },
          {
            name:'姜莹',
            name2:'Jiang Ying',
            name3:'Jiang',
            name4:'Ying',
            title1: "《艰难与辉煌》",
            title2: "“Hardship and Glory”",
            content1:
              "20世纪的中华民族经历了百年沧桑，是革命先烈对实现共产主义的理想信念，一路艰难走来。乐曲该作品以叙述性的创作方式展开，主题动机运用了《国际歌》的素材贯穿始终，使音乐具有强烈的英雄主义气质。我们缅怀英雄，是他们用热血和信仰铸就了革命的伟大胜利。这是一部用民族管弦乐交响化的理念来创作的作品，体现了波澜壮阔的革命历史史诗。今天，我们不忘初心，牢记使命，为中华民族崭新的时代谱写辉煌的乐章。此曲献给建党100周年！",
            content2:
              "The people of Zhonghua had gone through a hundred years of vicissitudes in the 20th century. It was the ideal and belief of revolutionary martyrs to realize communism that brought about China today. The work exposes via a narrative manner and the theme is adopted from “L’Internationale), which radiates a strong sense of heroism. We remember the heroes, it is they who achieved the great success of the revolution with their passion and faith. This is a work that aims to symphonize the Chinese traditional orchestra and to present the epic history of the revolution. Today, we stay true to our original aspiration and founding mission and compose the glorious movements for the new era of Zhonghua people. This work is a tribute to the 100th Anniversary of the Founding of CPC.",
            image: require("../../assets/media/media-6.png")
          },
          {
            name:'李滨扬',
            name2:'Li Binyang',
            name3:'Li',
            name4:'Binyang',
            title1: "《吴越春秋》",
            title2: "“Wu Yue Eras”",
            content1:
              "该作品以优美的曲调与富有内涵的意境，主要描写吴越大地在春秋时期的重大历史事件与丰富的文化成就，歌颂了祖国悠久灿烂的历史文化，揉发了爱国情怀！",
            content2:
              `The history of Wu Yue Eras was like a giant scroll painting, a thrilling saga. Observing the history, many of the familiar historical figures, stories and allusions occurred in the eras. The music attempts to use symphonic techniques from the composer's own understanding and perspective to connect and display the representative historical facts and stories in the thrilling Wu Yue epic. The work uses part of Kunqu tune as the original material. The whole symphony is composed of five parts and is performed continuously: "Wu and Yue Strive for Hegemony", "Endurance of Hardship", "Sword of the Yue Maiden", "Resurgence of War" and "Behold Jiangnan".`,
            image: require("../../assets/media/media-3.png") 
          },
          {
            name:'郭文景',
            name2:'Guo Wenjing ',
            name3:'Guo',
            name4:'Wenjing',
            title1: "《桃花庵》",
            title2: "“Peach Blossom Cottage”",
            content1:
              "该作品取自明代画家、文学家、诗人唐寅创作的一首七言古诗《桃花庵歌》，主要表达了诗人乐于归隐、淡泊功名、不愿与世俗交接追求闲适的生活态度，乐曲以绵延的艺术张力，歌颂了古人追求自由、珍视个体生命价值的可贵精神与不和世俗同流的刚正风骨！",
            content2:
              "The work was inspired by the seven-sentence ancient poem “Song of the Peach Blossom Cottage” written by Tang Yin (painter, writer, and poet of Ming Dynasty). It mainly expresses the poet’s life attitude that he is willing to live away from the secular world and indifferent to fame. With the constant tension of the music, the work eulogizes the valuable spirit and integrity of the ancient people who pursued freedom and cherished the value of life.",
            image: require("../../assets/media/media-7.png") 
          },
        ],
        3: [
          {
            name:'唐建平',
            name2:'Tang Jianping',
            name3:'Tang',
            name4:'Jianping',
            title1: "《江河湖海颂》",
            title2: "“Ode to Rivers, Lakes and the Sea”",
            content1:
              "江苏，地处中国的东部，山清水秀，人文荟萃，江河湖海在这里汇集，历史文脉在这里交聚。这里有中国当今社会发展最典型意义的“苏南模式”、“昆山之路”和“张家港精神”，成为新中国建立70多年、中国共产党建党100年来，中国现代历史发展成就的见证。乐曲就是讴歌了这片土地上的人文精神、风貌神韵和风土人情。",
            content2:
              "Jiangsu people have a mind as broad as the rivers, lakes and the sea since ancient times. The work eulogizes the water-cultivated Jiangsu’s humanism and nature, the great journey and history of the building of CPC, the transformation on the land of China since hundred years ago, and the great achievements until the 100th Anniversary of the Founding of CPC.",
            image: require("../../assets/media/media-8.png")
          },
          {
             name:'徐昌俊',
            name2:'Xu Changjun',
            name3:'Xu',
            name4:'Changjun',
            title1: "《虎丘的传说》",
            title2: "“The Legend of Huqiu”",
            content1:
              "宋代大诗人苏东坡写下“尝言过姑苏不游虎丘，不谒闾丘，乃二欠事”的千古名言。该作品以虎丘各种历史典故与美丽传说为素材，创设穿越时空的美妙意境，歌颂中华民族悠久而灿烂的历史文化！",
            content2:
              "The great poet of Song Dynasty - Su Dongpo wrote the famous phrase: “Coming to Suzhou, it’ll be a regret if I don’t go to Huqiu and pay a visit to my friend Lv Qiu”. The work refers to the history and legend that occurred in Huqiu, traveling in time and space, it eulogizes the brilliant historical culture of the Zhonghua people.",
            image: require("../../assets/media/media-9.png")
          },
          {
             name:'唐建平',
            name2:'Tang Jianping',
            name3:'Tang',
            name4:'Jianping',
            title1: "唐建平《苏风三月》",
            title2: "“The Suzhou Wind in March”",
            content1:
              "该作品描绘了姑苏大地春天时分的自然景象，欢快悠扬，甜美抒情，使人仿佛置身于幽曲明净、精巧秀丽的姑苏园林和春光明媚的江南原野之中，展示了江南三月，万物争春，一派人间天堂春意盎然的美景！象征在党的领导下，国家各项事业蒸蒸日上、人民生活幸福安康的盛世景象！",
            content2:
              "The work depicts the nature view of the Suzhou in spring season. Set in a happy mood, sweet and sentimental, the music brings the audience into a beautiful garden and bright wilderness in Jiangnan in spring season, presenting the March in Jiangnan, all living trees and animals are vibrant and full of energy, symbolizing that under the leadership of the Party, the country develops fast in various fields, and the people are living healthily in prosperity!",
            image: require("../../assets/media/media-8.png")
          },
          {
            name:'张朝',
            name2:'Zhang Zhao',
            name3:'Zhang',
            name4:'Zhao',
            title1: "《苏州日记》",
            title2: "“Suzhou Diary”",
            content1:
              "作品以苏州人的生活经历为线索，展现苏州人多彩的人生与对美好生活向往的火热情怀，歌颂了当代中国的繁荣富强与中国人民走进新时代过上的幸福日子！",
            content2:
              "The work was inspired by the living experience of Suzhou citizens, showcasing the wonderful life and passion of living of the people, eulogizing the prosperity of China and the happy life of the Chinese people in the new era!",
          image: require("../../assets/media/media-4.png") 
          },
        ],
      },
      dateTitle: {
        1: "2018~2019",
        2: "2019~2020",
        3: "2020~2021",
      },
    };
  },
  computed: {
     contents() {
      return this.dataSoure[this.id]
    },
    titleText(){
      return this.dateTitle[this.id]
    }
  },
  created(){
    this.id = this.$route.params.id || 0;
  },
  mounted() {},
  methods: {
    // getCompanys(){
    // }
  },
};
</script>

